import { useContext, useState } from "react";
import ListasContext from "../context/ListasContext";

const initialForm = {
  name: "",
  password: "",
};
const UserLoging = () => {
  const { handleLogging } = useContext(ListasContext);
  const [form, setForm] = useState(initialForm);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!form.name) {
      return;
    }
    handleLogging(form);
  };
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div>
      <div className="container p-1">
        <div className="row">
          <div className="col-md-4 mx-auto">
            <div className="card text-center">
              <div className="card-header">
                <h3>Inicio de sesión</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      placeholder="Código de usuario"
                      onChange={handleChange}
                      value={form.name}
                      className="form-control"
                      autoFocus
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      name="password"
                      placeholder="Contraseña"
                      onChange={handleChange}
                      value={form.password}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <button className="btn btn-success btn-block">
                      Iniciar sesión
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLoging;
