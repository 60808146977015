import { useContext } from "react";
import UserLoging from "./UserLoging";
import SelectList from "./SelectList";
import GestorListas from "./GestorLista";
import ListasContext from "../context/ListasContext";
import { Navigate, Route, Routes, HashRouter } from "react-router-dom";
import CrearListas from "./CrearLista";
import ListElements from "./ListElements";

const MyApp = () => {
  const { isLogged, listSelected } = useContext(ListasContext);
  return (
    <div>
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={
              isLogged ? (
                <Navigate to="/listas/select" replace={true} />
              ) : (
                <UserLoging />
              )
            }
          />
          <Route
            path="/listas/select"
            element={
              !isLogged ? <Navigate to="/" replace={true} /> : <SelectList />
            }
          />
          <Route
            path="/listas/datos"
            element={
              listSelected ? (
                <GestorListas />
              ) : (
                <Navigate to="/listas/select" replace={true} />
              )
            }
          />
          <Route
            path="/listas/create"
            element={
              !isLogged ? <Navigate to="/" replace={true} /> : <CrearListas />
            }
          />
          <Route
            path="/listas/elementos"
            element={
              listSelected ? (
                <ListElements />
              ) : (
                <Navigate to="/listas/select" replace={true} />
              )
            }
          />
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </HashRouter>
      {/* {!isLogged && <UserLoging />}
      {isLogged && !listSelected && <SelectList />}
      {listSelected && <GestorListas />} */}
    </div>
  );
};

export default MyApp;
