import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ListasContext from "../context/ListasContext";

const initialForm = {
  name: "",
  label: "",
  type: 0,
  negative: false,
};
const initialErrors = {
  name: "",
  label: "",
};

const CrearListas = () => {
  const { addList, isListNameUsed, dialogo } = useContext(ListasContext);
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState(initialErrors);
  const [c1, setC1] = useState(null);
  const [c2, setC2] = useState(null);
  const [marca, setMarca] = useState(null);
  const validateForm = () => {
    const { name, label } = form;
    let kg = null;
    const newErrors = {};
    if (!name || name === "") {
      newErrors.name = "Se ha de indicar un nombre";
      kg = c1;
    } else if (isListNameUsed(name)) {
      newErrors.name = "El nombre indicado ya se ha usado";
      kg = c1;
    }
    if (!label || label === "") {
      newErrors.label = "Se ha de indicar una etiqueta para el campo";
      if (!kg) kg = c2;
    }
    // Se define el campo que se ha de marcar por error (si procede)
    // Aquí no se puede poner el foco porque el cambio de estado es asíncrono y no se ve
    // Para poderlo ver se usa un efecto
    setMarca(kg);
    return newErrors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      addList(form);
      dialogo.alert("Lista grabada satisfactoriamente.").then((res) => {
        navigate("/listas/select", { replace: true });
      });
    }
  };
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };
  let navigate = useNavigate();
  // Con este efecto se consigue realizar el marcado del campo erróneo (si hay alguno a marcar)
  useEffect(() => {
    if (marca) marca.focus();
  }, [marca]);
  return (
    <div>
      <div className="container p-1">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-5 mx-auto">
            <div className="card">
              <div className="card-header text-center">
                <h3>Crear nueva lista</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="name" className="form-label">
                      Nombre
                    </label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Nombre de la lista"
                      ref={(c) => setC1(c)}
                      className={`form-control ${
                        errors.name ? "is-invalid" : ""
                      }`}
                      onChange={(e) => setField("name", e.target.value)}
                    />
                    <div className="invalid-feedback">{errors.name}</div>
                  </div>
                  <div className="form-check form-check-inline my-3">
                    <input
                      className="form-check-input"
                      name="type"
                      id="radiop1"
                      type="radio"
                      value="0"
                      onChange={(e) => setField("type", Number(e.target.value))}
                      checked={form.type === 0 ? true : false}
                    />
                    <label htmlFor="radiop1" className="form-check-label mr-3">
                      Contador
                    </label>
                  </div>
                  <div className="form-check form-check-inline my-3">
                    <input
                      className="form-check-input"
                      name="type"
                      id="radiop2"
                      type="radio"
                      value="1"
                      onChange={(e) => setField("type", Number(e.target.value))}
                      checked={form.type === 1 ? true : false}
                    />
                    <label htmlFor="radiop2" className="form-check-label">
                      Lista
                    </label>
                  </div>
                  <div className="my-1">
                    <label htmlFor="label" className="form-label">
                      Etiqueta del campo
                    </label>
                    <input
                      type="text"
                      name="label"
                      placeholder="Etiqueta del campo"
                      ref={(c) => setC2(c)}
                      className={`form-control ${
                        errors.label ? "is-invalid" : ""
                      }`}
                      onChange={(e) => setField("label", e.target.value)}
                    />
                    <div className="invalid-feedback">{errors.label}</div>
                  </div>
                  {form.type === 0 && (
                    <div className="form-check my-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="negative"
                        onChange={(e) => setField("negative", e.target.checked)}
                        checked={form.negative}
                      />
                      <label htmlFor="negative" className="form-check-label">
                        Permitir valores negativos
                      </label>
                    </div>
                  )}
                  <button className="btn btn-success btn-block" type="submit">
                    Aceptar
                  </button>
                  <button
                    className="btn btn-primary btn-block"
                    onClick={() =>
                      navigate("/listas/select", { replace: true })
                    }
                  >
                    Cancelar
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrearListas;
