import { useContext } from "react";
import ListasContext from "../context/ListasContext";

const ItemLista = ({ id, text, value, negative, checked }) => {
  const { updateData, updateCheck, lalista } = useContext(ListasContext);
  return (
    <tr>
      <td className={`${checked ? "tachado" : ""}`}>{text}</td>
      {lalista.type === 0 && (
        <>
          <td>{value}</td>
          <td align="center">
            <button
              className="btn btn-primary mx-1"
              onClick={() => updateData(id, 1)}
            >
              <i className="bi bi-plus"></i>
            </button>
            <button
              className="btn btn-primary mx-1"
              onClick={() => updateData(id, -1)}
              disabled={!negative && value < 1}
            >
              <i className="bi bi-dash"></i>
            </button>
          </td>
        </>
      )}
      {lalista.type === 1 && (
        <>
          <td>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                checked={checked}
                onChange={(e) => updateCheck(id, e.target.checked)}
              />
            </div>
          </td>
        </>
      )}
    </tr>
  );
};

export default ItemLista;
