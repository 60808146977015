import { useEffect } from "react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListasContext from "../context/ListasContext";

const ListElements = () => {
  const {
    lalista,
    listData,
    titleCase,
    updateDescription,
    addItemToList,
    deleteItemFromList,
  } = useContext(ListasContext);
  const [inedit, setInedit] = useState(false);
  const [dataToEdit, setDataToEdit] = useState("");
  const [campo, setCampo] = useState(null);
  const [error, setError] = useState("");
  const [indice, setIndice] = useState(-1);
  const [adding, setAdding] = useState(false);
  let navigate = useNavigate();
  const editar = (idx) => {
    // let indice = Number(idx);
    setDataToEdit(listData[idx].name);
    setInedit(true);
    setIndice(idx);
  };
  const cancelar = () => {
    setDataToEdit("");
    setInedit(false);
    setIndice(-1);
  };
  const aceptar = () => {
    if (dataToEdit === "") {
      setError("La descripción no puede estar vacía.");
      // campo.focus();
      return;
    }
    if (!adding) {
      updateDescription(indice, dataToEdit);
    } else {
      addItemToList(dataToEdit);
    }
    setDataToEdit("");
    setIndice(-1);
    setInedit(false);
    setError("");
  };
  const nuevo = () => {
    setAdding(true);
    setInedit(true);
    // campo.focus();
  };
  useEffect(() => {
    if (campo) campo.focus();
  }, [campo]);

  const borrar = (idx) => {
    deleteItemFromList(idx);
    setDataToEdit("");
    setIndice(-1);
    setInedit(false);
    setError("");
  };
  return (
    <div className="container p-1">
      <div className="col-12 col-md-6 mx-auto">
        <div className="card">
          <div className="card-header">
            <h3>Lista: {titleCase(lalista.name)}</h3>
            <h5>Edición de elementos</h5>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>{titleCase(lalista.label)}</th>
                <th style={{ textAlign: "center" }}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {listData.map((el, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{el.name}</td>
                    <td style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={inedit}
                        onClick={() => editar(index)}
                      >
                        <i className="bi bi-pencil"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger ml-1"
                        disabled={inedit}
                        onClick={() => borrar(index)}
                      >
                        <i className="bi bi-trash"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {inedit && (
          <div className="row p-2">
            <div className="col-7">
              <input
                type="text"
                name="name"
                className={`form-control ${error ? "is-invalid" : ""}`}
                value={dataToEdit}
                ref={(c) => setCampo(c)}
                onChange={(e) => setDataToEdit(e.target.value)}
              />
              <div className="invalid-feedback">{error}</div>
            </div>
            <div className="col-5 text-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={aceptar}
              >
                <i className="bi bi-check"></i>
              </button>
              <button
                type="button"
                className="btn btn-danger ml-1"
                onClick={cancelar}
              >
                <i className="bi bi-x"></i>
              </button>
            </div>
          </div>
        )}

        <div className="card-footer">
          {!inedit && (
            <button className="btn btn-success btn-block" onClick={nuevo}>
              Añadir nuevo elemento
            </button>
          )}
          <button
            className="btn btn-success btn-block"
            onClick={() => navigate("/listas/select", { replace: true })}
          >
            Volver
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListElements;
