import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import ListasContext from "../context/ListasContext";

const SelectList = () => {
  const {
    listas,
    user: usuario,
    handleSelect,
    listSelected,
    titleCase,
    lalista,
    listData,
    dialogo,
    deleteList,
    logout,
  } = useContext(ListasContext);
  let id = "listas";
  let navigate = useNavigate();
  let hasData = listData.length > 0;
  const salir = () => {
    logout();
    navigate("/", { replace: true });
  };
  const borrar = () => {
    dialogo
      .confirm(`¿Estás seguro de querer borrar la lista ${lalista.name}`, {
        accept: "Sí",
        cancel: "No",
      })
      .then((res) => {
        if (res) deleteList(lalista.id);
      });
  };
  return (
    <div>
      <div className="card col-12 col-md-4 mx-auto">
        <div className="card-header">
          <h3>Listas de {titleCase(usuario)}</h3>
        </div>
        <select
          className="form-control mb-1 my-3"
          name={id}
          id={id}
          onChange={handleSelect}
          defaultValue={listSelected && lalista.name !== "" ? lalista.name : ""}
        >
          <option value="">Selecciona la lista</option>
          {listas &&
            listas.map((el) => (
              <option value={el.name} key={el.id}>
                {titleCase(el.name)}
              </option>
            ))}
        </select>
        <button
          className="btn btn-primary btn-block"
          disabled={!listSelected || !hasData}
          onClick={() => navigate("/listas/datos", { replace: true })}
        >
          Gestionar datos
        </button>
        <button
          className="btn btn-primary btn-block"
          disabled={!listSelected}
          onClick={() => navigate("/listas/elementos", { replace: true })}
        >
          Editar elementos
        </button>
        <button
          className="btn btn-success btn-block"
          onClick={() => navigate("/listas/create", { replace: true })}
        >
          Crear nueva
        </button>
        <button
          className="btn btn-danger btn-block"
          onClick={borrar}
          disabled={!listSelected}
        >
          Eliminar lista
        </button>
        <button className="btn btn-success btn-block" onClick={salir}>
          Salir
        </button>
      </div>
    </div>
  );
};

export default SelectList;
