import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import ListasContext from "../context/ListasContext";
import ItemLista from "./ItemLista";

const GestorListas = () => {
  const {
    lalista: lista,
    listData,
    titleCase,
    dialogo,
    resetData,
  } = useContext(ListasContext);
  let navigate = useNavigate();
  if (!listData) return null;
  const resetear = () => {
    dialogo
      .confirm(
        `¿Estás seguro de querer resetear todos los valores de la lista ${titleCase(
          lista.name
        )}?`,
        {
          accept: "Sí",
          cancel: "No",
        }
      )
      .then(() => {
        resetData();
      });
  };
  return (
    <div>
      <div className="container p-1">
        <div className="col-12 col-md-6 mx-auto">
          <div className="card">
            <div className="card-header">
              <h3>Lista: {titleCase(lista.name)}</h3>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>{titleCase(lista.label)}</th>
                  <th>{lista.type === 0 ? "#" : "¿?"}</th>
                  {lista.type === 0 && (
                    <th style={{ textAlign: "center" }}>Acciones</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {listData.map((el, index) => (
                  <ItemLista
                    key={index}
                    id={index}
                    text={el.name}
                    value={el.quantity}
                    negative={el.negative}
                    checked={el.checked}
                  />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="3">
                    <button
                      className="btn btn-block btn-success"
                      onClick={resetear}
                    >
                      Resetear valores
                    </button>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <button
                      className="btn btn-block btn-success"
                      onClick={() =>
                        navigate("/listas/select", { replace: true })
                      }
                    >
                      Volver
                    </button>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestorListas;
