import "./App.css";
import MyApp from "./components/MyApp";
import { ListasProvider } from "./context/ListasContext";

// const hash = bcrypt.hashSync("dosadi", salt);

function App() {
  return (
    <div>
      <ListasProvider>
        <MyApp />
      </ListasProvider>
    </div>
  );
}

export default App;
