export const helpData = () => {
  const getData = (name) => {
    let datos = JSON.parse(localStorage.getItem(name));
    return datos;
  };
  const setData = (name, data) => {
    localStorage.setItem(name, JSON.stringify(data));
  };
  const deleteData = (name) => {
    localStorage.removeItem(name);
  };
  return {
    getData,
    setData,
    deleteData,
  };
};
