import { createContext, useState } from "react";
import { helpData } from "../helpers/helpData";
import bcrypt from "bcryptjs";
import Dialog from "../components/Dialog";
import "../components/Dialog.css";

const Users = [
  {
    id: 1,
    name: "antonio",
    password: "$2a$10$/FiR4Te59/wER4zUfOoCIePzH6plTaPCr2GxDOEkwmMAMuYbe4t6a",
  },
];
const defaultList = [
  {
    id: 1,
    name: "mermelada",
    type: 0,
    label: "Sabor",
    negative: false,
  },
];
const listaMerm = [
  {
    name: "Albaricoques",
    quantity: 0,
  },
  {
    name: "Melocotones",
    quantity: 0,
  },
  {
    name: "Ciruelas verdes",
    quantity: 0,
  },
  {
    name: "Fresas",
    quantity: 0,
  },
  {
    name: "Ciruelas rojas",
    quantity: 0,
  },
  {
    name: "Frutas del bosque",
    quantity: 0,
  },
  {
    name: "Cerezas",
    quantity: 0,
  },
];
const voidList = {
  name: "",
  type: 0,
};
const dialogo = new Dialog();
const ListasContext = createContext();

const ListasProvider = ({ children }) => {
  const [isLogged, setIsLogged] = useState(false); // Indica si el usuario se ha logado
  const [listSelected, setListSelected] = useState(false); // Indica si se ha seleccionado una lista
  const [listas, setListas] = useState([]); // Contiene todas las listas del usuario
  const [lalista, setLalista] = useState(voidList); // Contiene el nombre de la lista seleccionada
  const [listData, setListData] = useState([]); // Contiene los datos de la lista seleccionada
  const [user, setUser] = useState(""); // Contiene el nombre del usuario
  const logout = () => {
    setIsLogged(false);
    setListSelected(false);
    setListas([]);
    setLalista(voidList);
    setListData([]);
    setUser("");
  };
  // Gestiona la selección de lista en SelectList.js
  const handleSelect = (e) => {
    let quelista = listas.filter((el) => el.name === e.target.value);
    setLalista(quelista[0]);
    // setLalista(e.target.value);
    if (e.target.value === "") {
      setListSelected(false);
      return;
    }
    let ldata = helpData().getData(e.target.value);
    if (!ldata) ldata = [];
    if (
      ldata.length === 0 &&
      e.target.value.toLowerCase() === "mermelada" &&
      user === "antonio"
    ) {
      ldata = [...listaMerm];
    }
    setListData(ldata);
    helpData().setData(e.target.value, ldata);
    setListSelected(true);
  };
  // Añade una nueva lista
  const addList = (data) => {
    data.name = data.name.toLowerCase();
    // Obtener el valor máximo del id de los objetos del array
    let ids = [0];
    let nueva = {};
    if (listas) {
      ids = listas.map((object) => {
        return object.id;
      });
    }
    let xid = Math.max(...ids) + 1;
    data.id = xid;
    // Añadir nueva lista al array
    nueva.id = data.id;
    nueva.name = data.name;
    nueva.type = data.type;
    nueva.label = data.label;
    if (data.type === 0) {
      nueva.negative = data.negative;
    }
    let tempdata = [];
    if (listas) {
      tempdata = [...listas];
    }
    tempdata.push(nueva);
    // Actualizar state
    setListas(tempdata);
    // Actualizar localStorage
    helpData().setData("listas", tempdata);
    setLalista(data);
    setListSelected(true);
  };
  // Elimina una lista (la que se tiene seleccionada)
  const deleteList = (id) => {
    let nombre = listas.filter((el) => el.id === id)[0].name;
    setListSelected(false);
    setLalista(voidList);
    setListData([]);
    let tempdata = listas.filter((el) => el.id !== id);
    setListas(tempdata);
    helpData().setData("listas", tempdata);
    helpData().deleteData(nombre);
  };
  // Actualiza el dato númerico de una lista del tipo contador
  const updateData = (e, cantidad) => {
    let tempdata = [...listData];
    let valor = tempdata[e].quantity;
    if (cantidad < 0 && valor < 1) {
      return;
    }
    valor += cantidad;
    tempdata[e].quantity = valor;
    setListData(tempdata);
    helpData().setData(lalista.name, tempdata);
  };
  // Actualiza la descripción del elemento de una lista
  const updateDescription = (idx, desc) => {
    let tempdata = [...listData];
    tempdata[idx].name = desc;
    setListData(tempdata);
    helpData().setData(lalista.name, tempdata);
  };
  // Actualiza el check de las listas de tipo 1
  const updateCheck = (idx, value) => {
    let tempdata = [...listData];
    tempdata[idx].checked = value;
    setListData(tempdata);
    helpData().setData(lalista.name, tempdata);
  };
  // Devolver todos los valores de los items de una lista a su valor inicial
  const resetData = () => {
    listData.forEach((el, index) => {
      if (lalista.type === 0) updateData(index, el.quantity * -1);
      else updateCheck(index, false);
    });
  };
  // Añadir un item a la lista seleccionada
  const addItemToList = (desc) => {
    let tempdata = [...listData];
    let nuevo;
    if (lalista.type === 0) {
      nuevo = {
        name: desc,
        quantity: 0,
      };
    } else {
      nuevo = {
        name: desc,
        checked: false,
      };
    }
    tempdata.push(nuevo);
    setListData(tempdata);
    helpData().setData(lalista.name, tempdata);
  };
  // Eliminar un item de la lista seleccionada
  const deleteItemFromList = (idx) => {
    let tempdata = [...listData];
    tempdata.splice(idx, 1);
    setListData(tempdata);
    helpData().setData(lalista.name, tempdata);
  };
  const handleLogging = (data) => {
    let d = data.name.toLowerCase();
    let aux = Users.filter((el) => el.name === d);
    if (aux.length === 0) {
      setIsLogged(true);
      setUser(data.name);
    } else {
      if (!bcrypt.compareSync(String(data.password), String(aux[0].password))) {
        setIsLogged(false);
        return;
      } else {
        setIsLogged(true);
        setUser(aux[0].name);
      }
    }
    let mil = helpData().getData("listas");
    if (d === "antonio") {
      if (!mil) {
        mil = defaultList;
        helpData().setData("listas", mil);
      }
      setListas(mil);
    } else {
      setListas(mil);
    }
  };
  function titleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(" ");
  }
  function isListNameUsed(listname) {
    if (!listas) return false;
    let esta = listas.filter((el) => el.name === listname.toLowerCase());
    return esta.length === 1;
  }

  const data = {
    isLogged,
    handleLogging,
    listSelected,
    listas,
    user,
    handleSelect,
    lalista,
    listData,
    updateData,
    updateCheck,
    titleCase,
    addList,
    isListNameUsed,
    dialogo,
    updateDescription,
    addItemToList,
    deleteItemFromList,
    deleteList,
    resetData,
    logout,
  };
  return (
    <ListasContext.Provider value={data}>{children}</ListasContext.Provider>
  );
};

export { ListasProvider };

export default ListasContext;
